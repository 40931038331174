<template>
    <div class="box">
        <div class="title">
            出证记录
        </div>
        <record-search-form :searchForm.sync="listParams" @submit="getList" />
        <div class="main">
            <el-table border :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;"
                :row-style="{ height: '80px' }">
                <el-table-column label="合同名称" show-overflow-tooltip prop="contract.subject">
                    <template slot-scope="scope">
                        <span>{{ scope.row.contract.subject || "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="合同编号" show-overflow-tooltip prop="contract.subject">
                    <template slot-scope="scope">
                        <span>{{ scope.row.contract.uuid || "--" }}</span>
                    </template>
                </el-table-column>
               
                <el-table-column label="出证类型" show-overflow-tooltip prop="certificateType">
                    <template slot-scope="scope">
                        <span>{{ certificateTypeRender(scope.row.certificateType) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="出证状态" show-overflow-tooltip prop="weixin">
                    <template slot-scope="scope">
                        <span>{{ certificateStateRender(scope.row.state) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="申请人" show-overflow-tooltip prop="account.name">
                    <template slot-scope="scope">
                        <span>{{ scope.row.emp.name || "--" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="申请时间" show-overflow-tooltip prop="account.name">
                    <template slot-scope="scope">
                        <span>{{ scope.row.updatedAt | timeFormat('yyyy-mm-dd hh:MM') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{row}">
                        <span @click="operate('chakan', row)" class="blue">查看</span>
                        <!-- <span>｜</span>
                        <span @click="operate('xiazai', row)" class="blue">下载</span> -->
                    </template>
                </el-table-column>
            </el-table>
            <hdq-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getList" />
        </div>
    </div>
</template>

<script>
import recordSearchForm from "./components/recordSearchForm.vue"
import hdqPagination from "@/components/hdqPagination.vue"
import selectData from "../../mixins/selectData"
export default {
    components: {
        recordSearchForm,
        hdqPagination
    },
    mixins: [selectData],
    data() {
        return {
            list: [],
            listParams: {
                keyword: ''
            }
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        certificateTypeRender(value) {
            return this.certificateType.find(res => res.value == value).label || "--"
        },
        certificateStateRender(value) {
            return this.certificateState.find(res => res.value == value).label || "--"
        },
        async getList() {
            const { current, size } = this.$refs.pagination
            const { listParams } = this
            const { status, data } = await this.$api.getCertificateRequestList({ current, size, ...listParams })
            if (status == 200) {
                this.list = data.records;
                this.$refs.pagination.createPagination(data)
            }

        },
        operate(type, res) {
            if (type == 'chakan') {
                window.open(`${this.$oss}${res.certificateUrl}`)
            } else if (type == 'xiazai') {
                window.open(`${this.$oss}${res.certificateUrl}`)
            }

        }
    }

}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/listStyle.scss";
.blue{
    color: #0062FF;
    cursor: pointer;
}
</style>